var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"2mIuPbQfWFgk5NS9iT5j7"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "/_marketing_assets";

import * as Sentry from '@sentry/nextjs';

import { getRuntimeEnvironment, isProduction } from './shared/environment';

const SENTRY_DSN = 'https://1cf4b989752b490ea5444fd20898f534@o83571.ingest.sentry.io/4504804404625408';

Sentry.init({
  enabled: isProduction(),
  dsn: SENTRY_DSN,
  environment: getRuntimeEnvironment(),
  // Filter errors from non-prod
  allowUrls: ['patreon.com'],
  // Matching prf config.
  // See: https://github.com/Patreon/patreon_react_features/issues/15039
  tracesSampleRate: 0,
  // Adding a sample rate here to reduce usage further
  sampleRate: 0.1,
  maxBreadcrumbs: 10,
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps

  // Some of this list came from https://docs.sentry.io/platforms/javascript/configuration/filtering/#decluttering-sentry
  // And some of it, is specific to us (looking at you, Avo)
  ignoreErrors: [
    // Start: PRF level ignored errors
    // This has been recommended to be ignored by the commmunity, and we also have observed instances of these
    'ResizeObserver loop',
    // End: PRF level ignored errors

    // patreon_marketing specific
    // Usually from client side navigation when requests are in-flight. Safe to ignore
    'AbortError: The operation was aborted.',
    // Non-user-impacting video events. Will map to things like `AbortError: The play() request was interrupted by a call to pause(). https://goo.gl/LdLk22`
    'AbortError: The play() request was interrupted',
  ],
});
